import request from '@/utils/request'

// 查询优惠券信息列表
export function listCoupon(query) {
  return request({
    url: '/coupon/coupon/list',
    method: 'get',
    params: query
  })
}

// 查询优惠券信息详细
export function getCoupon(id) {
  return request({
    url: '/coupon/coupon/' + id,
    method: 'get'
  })
}

// 新增优惠券信息
export function addCoupon(data) {
  return request({
    url: '/coupon/coupon',
    method: 'post',
    data: data
  })
}

// 修改优惠券信息
export function updateCoupon(data) {
  return request({
    url: '/coupon/coupon',
    method: 'put',
    data: data
  })
}

// 删除优惠券信息
export function delCoupon(id) {
  return request({
    url: '/coupon/coupon/' + id,
    method: 'delete'
  })
}
// 查询优惠券用户领取记录列表
export function listCouponUser(query) {
  return request({
    url: '/coupon/coupon/user/list',
    method: 'get',
    params: query
  })
}

// 查询优惠券用户领取记录详细
export function getCouponUser(id) {
  return request({
    url: '/coupon/coupon/user/' + id,
    method: 'get'
  })
}

// 新增优惠券用户领取记录
export function addCouponUser(data) {
  return request({
    url: '/coupon/coupon/user',
    method: 'post',
    data: data
  })
}
export function addCouponUsers(data) {
  return request({
    url: '/coupon/coupon/user/addCouponUsers',
    method: 'post',
    data: data
  })
}

// 修改优惠券用户领取记录
export function updateCouponUser(data) {
  return request({
    url: '/coupon/coupon/user',
    method: 'put',
    data: data
  })
}

// 删除优惠券用户领取记录
export function delCouponUser(id) {
  return request({
    url: '/coupon/coupon/user/' + id,
    method: 'delete'
  })
}
